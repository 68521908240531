const DEFAULT_URL = "https://api.authentickated.com/api/rest/site/config";

/**
 * Save the configuration to localStorage
 *
 * @param config The configuration object to save.
 * @param url The URL used to fetch this configuration, used to generate a unique key.
 */
function saveConfigToStorage(config: any, url: string) {
  // Generate a unique key for the config based on the URL
  const storageKey = `CBAR_FETCHED_CONFIG_${encodeURIComponent(url)}`;

  // Save to localStorage
  localStorage.setItem(storageKey, JSON.stringify(config));

  console.log(`Config for ${url} saved.`);
}

/**
 * Retrieve a cached configuration from localStorage.
 *
 * @param url The URL used to generate the key for the cached config.
 * @returns The cached configuration object, or null if not found or expired.
 */
function getCachedConfig(url: string): any | null {
  const storageKey = `CBAR_FETCHED_CONFIG_${encodeURIComponent(url)}`;
  const cachedConfig = localStorage.getItem(storageKey);

  if (!cachedConfig) return null;

  const parsedConfig = JSON.parse(cachedConfig);

  // Check if the cacheUntil time has expired
  if (parsedConfig.cacheUntil && new Date(parsedConfig.cacheUntil) > new Date()) {
    return parsedConfig;
  }

  return null;
}

/**
 * Fetch configuration from the specified provider URL with additional parameters.
 *
 * @param url         Required custom URL parameter to include in the request.
 * @param providerUrl Optional provider URL to fetch configuration from.
 * @param includeData Flag to include additional data, defaults to true.
 * @param method      HTTP method to use (GET or POST), defaults to GET.
 * @returns Promise resolving to the fetched JSON response.
 */
export async function fetchConfig(
  url: string,
  providerUrl: string = DEFAULT_URL,
  includeData: boolean = true,
  method: string = "GET"
): Promise<any> {
  if (!url) {
    throw new Error("The 'url' parameter is required for fetchConfig.");
  }

  let fetchUrl = providerUrl;

  const params = {
    url: url,
    include_data: includeData,
  };

  // If the method is GET, append parameters as query string
  if (method.toUpperCase() === "GET") {
    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      queryParams.append(key, String(value));
    });
    fetchUrl += `?${queryParams.toString()}`;
  }

  const options: RequestInit = {
    method: method.toUpperCase(),
    headers: { "Content-Type": "application/json" },
  };

  if (method.toUpperCase() !== "GET") {
    options.body = JSON.stringify(params);
  }

  const response = await fetch(fetchUrl, options);

  if (!response.ok) {
    throw new Error(
      `Failed to fetch config: ${response.status} ${response.statusText}`
    );
  }

  const responseData = await response.json();

  // Log the response before returning
  console.log("Fetched config successfully, now returning");

  return responseData;
}

class CbarFetchConfig extends HTMLElement {
  constructor() {
    super();
  }

  /**
   * Called when the element is added to the DOM.
   */
  async connectedCallback() {
    const url = this.getAttribute("url");
    const providerUrl = this.getAttribute("provider-url") || DEFAULT_URL;
    const includeDataAttr = this.getAttribute("include-data");
    const includeData = includeDataAttr !== "false"; // Defaults to true unless explicitly "false"
    const force = this.getAttribute("force") === "true"; // Default to false unless explicitly "true"
    const method = this.getAttribute("method") || "GET";

    if (!url) {
      console.error("The 'url' attribute is required for <cbar-fetch-config>.");
      return;
    }

    // Check cached config if force is not true
    if (!force) {
      const cachedConfig = getCachedConfig(url);

      if (
        cachedConfig &&
        (!includeData || (includeData && cachedConfig.data))
      ) {
        console.log(
          `Using config cached at ${cachedConfig.fetchedTime} until ${cachedConfig.cacheUntil} as force was not requested.`
        );
        (window as any).config = cachedConfig;

        // Dispatch a custom event to notify listeners
        window.dispatchEvent(
          new CustomEvent("cbar-config-fetched", {
            detail: { cachedConfig, url }, // Pass the configuration and URL as the event detail
            bubbles: true,
            composed: true,
          })
        );

        console.log(`Config for ${url} applied and event dispatched.`);
        return;
      }
    }

    try {
      const data = await fetchConfig(url, providerUrl, includeData, method);

      // Add the current date and time to the fetched data
      const fetchedTime = new Date();
      data.fetchedTime = fetchedTime.toISOString();

      // Add a cacheUntil time, 5 minutes after the fetchedTime
      data.cacheUntil = new Date(fetchedTime.getTime() + 5 * 60 * 1000).toISOString();

      // Set the global config
      (window as any).config = data;
      console.log("window.config set");

      // Save the config to storage and dispatch the custom event
      saveConfigToStorage(data, url);

      // Dispatch a custom event to notify listeners
      window.dispatchEvent(
        new CustomEvent("cbar-config-fetched", {
          detail: { data, url }, // Pass the configuration and URL as the event detail
          bubbles: true,
          composed: true,
        })
      );

      console.log(`Config for ${url} fetched, applied, saved and event dispatched.`);

    } catch (error) {
      console.error("Error fetching config:", error);
    }
  }
}

// Define the custom element
customElements.define("cbar-fetch-config", CbarFetchConfig);