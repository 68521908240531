/**
 * Web component to dynamically apply theme JSON keys as CSS variables.
 * By default, applies variables globally to the document root.
 * If the `shadow` attribute is present, variables are scoped to the component's shadow DOM.
 */
class CbarJsonTheme extends HTMLElement {
  private themeObject: ThemeObject | null = null;
  private mediaQueryList: MediaQueryList;

  constructor() {
    super();
    // Attach a shadow DOM to the component
    this.attachShadow({ mode: 'open' });

    // Initialize the media query list for detecting theme preference changes
    this.mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
  }

  static get observedAttributes(): string[] {
    return ['theme', 'shadow']; // Observe theme and shadow attributes
  }

  attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (name === 'theme' && newValue) {
      try {
        this.themeObject = JSON.parse(newValue);
        this.applyCurrentTheme();
      } catch (e) {
        console.error('Invalid JSON provided for theme attribute:', e);
      }
    } else if (name === 'shadow') {
      this.applyCurrentTheme(); // Reapply theme if shadow attribute changes
    }
  }

  connectedCallback(): void {
    const theme = this.getAttribute('theme');
    if (theme) {
      try {
        this.themeObject = JSON.parse(theme);
        this.applyCurrentTheme();
      } catch (e) {
        console.error('Invalid JSON provided for theme attribute:', e);
      }
    }

    // Listen for changes to the prefers-color-scheme media query
    this.mediaQueryList.addEventListener('change', this.handleThemeChange);
  }

  disconnectedCallback(): void {
    // Clean up the media query listener when the component is disconnected
    this.mediaQueryList.removeEventListener('change', this.handleThemeChange);
  }

  /**
   * Handles changes in the user's system theme preference.
   * Automatically applies the new theme when the preference changes.
   */
  private handleThemeChange = (): void => {
    this.applyCurrentTheme();
  };

  /**
   * Applies the current theme based on the user's system preference (light or dark).
   */
  private applyCurrentTheme(): void {
    if (!this.themeObject) return;

    const selectedSubTheme = this.mediaQueryList.matches ? 'dark' : 'light';
    const variables = this.themeFromObject(this.themeObject, selectedSubTheme);
    const applyToShadow = this.hasAttribute('shadow');
    this.applyCssVariables(variables, applyToShadow);
  }

  /**
   * Converts a JSON key to a CSS variable name.
   * Example: `onPrimary` → `--cbar-sys-color-on-primary`.
   * @param key The JSON key to convert.
   * @returns The CSS variable name.
   */
  private convertKeyToCssVariable(key: string): string {
    return '--cbar-sys-color-' + key.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);
  }

  /**
   * Generates a mapping of CSS variables based on the theme JSON object.
   * Dynamically determines the current theme (`dark` or `light`).
   * @param themeObject The theme JSON object containing `dark` and `light` sub-themes.
   * @param selectedSubTheme The sub-theme to use ('dark' or 'light').
   * @returns A record of CSS variable names mapped to their values.
   */
  private themeFromObject(themeObject: ThemeObject, selectedSubTheme: 'dark' | 'light'): Record<string, string> {
    const objectToCheck: Record<string, string> = themeObject[selectedSubTheme] || {};
    const variables: Record<string, string> = {};

    // Convert all keys in the sub-theme to CSS variables
    for (const [key, value] of Object.entries(objectToCheck)) {
      const cssVariable = this.convertKeyToCssVariable(key);
      variables[cssVariable] = value;
    }

    return variables;
  }

  /**
   * Applies CSS variables to the specified scope.
   * @param variables A record of CSS variable names mapped to their values.
   * @param applyToShadow Whether to apply the variables to the shadow DOM or globally.
   */
  private applyCssVariables(variables: Record<string, string>, applyToShadow: boolean): void {
    if (applyToShadow && this.shadowRoot) {
      // Apply variables to the component's shadow DOM
      const style = document.createElement('style');
      const cssVariables = Object.entries(variables)
        .map(([key, value]) => `${key}: ${value};`)
        .join('\n');

      style.textContent = `:host { ${cssVariables} }`;
      this.shadowRoot.innerHTML = ''; // Clear old styles
      this.shadowRoot.appendChild(style);
    } else {
      // Apply variables to the document root
      Object.entries(variables).forEach(([key, value]) => {
        document.documentElement.style.setProperty(key, value);
      });
    }
  }
}

// Define the types for the JSON structure
interface ThemeObject {
  dark?: Record<string, string>; // Sub-theme for dark mode
  light?: Record<string, string>; // Sub-theme for light mode
  typography?: any[]; // Additional metadata (currently unused)
}

// Define the custom element
customElements.define('cbar-json-theme', CbarJsonTheme);

export default CbarJsonTheme;